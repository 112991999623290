var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('REISEN.COMMON.VORGAENGE'),expression:"'REISEN.COMMON.VORGAENGE'"}],staticClass:"card-title font-weight-bolder"}),_c('div',{staticClass:"card-toolbar"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"size":"sm","name":"check-button","button":""},model:{value:(_vm.isBuchungFilterEnabled),callback:function ($$v) {_vm.isBuchungFilterEnabled=$$v},expression:"isBuchungFilterEnabled"}},[_vm._v(" Nur fixe Buchungen anzeigen ("+_vm._s(_vm.isBuchungFilterEnabled ? 'An' : 'Aus')+") ")])],1)]),_c('div',{staticClass:"card-body"},[(_vm.vorgaenge)?_c('VorgangTable',{attrs:{"items":_vm.filteredVorgaenge,"isLoading":_vm.isLoading,"hiddenItemsCount":_vm.isBuchungFilterEnabled ? this.vorgaenge.length - this.filteredVorgaenge.length : null,"displayFieldsByKey":[
        'id',
        'reisezeitraum',
        'kunde',
        'zimmer',
        'npx',
        'agentur',
        'brutto',
        'isReleased',
        'stateSlot',
      ],"dokumenteCheckRequired":_vm.dokumenteCheckRequired,"passdatenCheckRequired":_vm.passdatenCheckRequired,"reiseterminId":_vm.reiseterminObj.id},on:{"update-single-item":_vm.updateItemInRow},scopedSlots:_vm._u([{key:"stateSlot",fn:function(row){return [_c('VorgangStateWidget',{attrs:{"state":row.item.blankState}})]}}],null,false,1962827163)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }