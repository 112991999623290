<template>
  <div class="card card-custom">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder" v-t="'REISEN.COMMON.VORGAENGE'"></h3>
      <div class="card-toolbar">
        <b-form-checkbox size="sm" class="mr-1" v-model="isBuchungFilterEnabled" name="check-button" button>
          Nur fixe Buchungen anzeigen ({{ isBuchungFilterEnabled ? 'An' : 'Aus' }})
        </b-form-checkbox>
      </div>
    </div>
    <div class="card-body">
      <VorgangTable
        v-if="vorgaenge"
        :items="filteredVorgaenge"
        :isLoading="isLoading"
        :hiddenItemsCount="
          isBuchungFilterEnabled ? this.vorgaenge.length - this.filteredVorgaenge.length : null
        "
        :displayFieldsByKey="[
          'id',
          'reisezeitraum',
          'kunde',
          'zimmer',
          'npx',
          'agentur',
          'brutto',
          'isReleased',
          'stateSlot',
        ]"
        :dokumenteCheckRequired="dokumenteCheckRequired"
        :passdatenCheckRequired="passdatenCheckRequired"
        :reiseterminId="reiseterminObj.id"
        @update-single-item="updateItemInRow"
      >
        <template #stateSlot="row">
          <VorgangStateWidget :state="row.item.blankState" />
        </template>
      </VorgangTable>
    </div>
  </div>
</template>

<script>
import { GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER } from '@/core/vorgaenge/stores/vorgaenge.module';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import { mapState } from 'vuex';

export default {
  components: { VorgangTable, VorgangStateWidget },
  props: {
    reiseterminObj: { type: Object },
    tabname: { type: String },
  },
  data() {
    return {
      isBuchungFilterEnabled: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      isInitialLoading: state => state.vorgaenge.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
    }),
    dokumenteCheckRequired() {
      return this.reiseterminObj?.gueltigeKonfiguration?.dokumente;
    },
    passdatenCheckRequired() {
      return this.reiseterminObj?.gueltigeKonfiguration?.passdaten;
    },
    filteredVorgaenge() {
      return this.isBuchungFilterEnabled
        ? this.vorgaenge.filter(vorgang => vorgang.blankState === 'Buchung' || vorgang.isReleased)
        : this.vorgaenge;
    },
  },
  watch: {
    reiseterminObj() {
      this.loadData();
    },
  },
  mounted() {
    if (Array.isArray(this.vorgaenge) && this.vorgaenge.length <= 0 && !this.isLoading) {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, {
        reiseterminId: this.reiseterminObj.id,
      });
    },
    async updateItemInRow() {
      this.$emit('update-single-item');
    },
  },
};
</script>
